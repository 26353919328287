'use client'

import React from 'react'
import NextError from 'next/error'
import { useRouter } from 'next-nprogress-bar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Link from '@/components/Link'

const ErrorPageBack = () => {
    const router = useRouter()
    return (
        <Button className="go-back-btn" onClick={() => router.back()} variant="contained" startIcon={<ArrowBackIcon />}>
            Go Back
        </Button>
    )
}
const ErrorPageReset = ({ reset }: Pick<ErrorPageProps, 'reset'>) => (
    <Button className="go-back-btn" onClick={reset} variant="contained" startIcon={<RestartAltIcon />}>
        Try Again
    </Button>
)

export type ErrorPageProps = {
    error: Error & { digest?: string }
    reset: () => void
    statusCode: number
}
const ErrorPage = ({ error, reset, statusCode = 500 }: Partial<ErrorPageProps>): JSX.Element => (
    <Stack spacing={2} height="100vh" justifyContent="center" alignItems="center">
        <Box sx={{ '>div': { height: 'initial !important', mb: 2 } }}>
            <NextError statusCode={statusCode} title={error?.message} />
        </Box>
        <Stack direction="row" spacing={4} justifyContent="center">
            {reset ? <ErrorPageReset reset={reset} /> : <ErrorPageBack />}
            <Button>
                <Link href="/" underline="none">
                    Return Home
                </Link>
            </Button>
        </Stack>
    </Stack>
)

export default ErrorPage
